.comment-management {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2, h3 {
    color: #333;
  }
  
  .error {
    color: red;
    font-weight: bold;
  }
  
  .comment-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .comment-table th, .comment-table td {
    text-align: left;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .comment-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .comment-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  
  .icon-button.approve {
    color: green;
  }
  
  .icon-button.delete {
    color: red;
  }
  