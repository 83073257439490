.enter-about-us {
  max-width: 700px; /* Increased max width for better readability */
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.enter-about-us h2 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.enter-about-us .message {
  color: green;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}

.enter-about-us form {
  display: flex;
  flex-direction: column;
}

.enter-about-us textarea {
  margin-bottom: 20px;
  padding: 12px;
  font-size: 16px;
  resize: none;
  border: 2px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s ease-in-out;
}

.enter-about-us textarea:focus {
  border-color: #007bff;
  outline: none;
}

.enter-about-us button {
  background-color: #333; /* Dark button */
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.enter-about-us button:hover {
  background-color: #555; /* Darker shade on hover */
}

.enter-about-us button:disabled {
  background-color: #999; /* Disabled button */
  cursor: not-allowed;
}
