.about-us {
  text-align: center;
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  margin: 20px auto;
  max-width: 800px;
  word-wrap: break-word; /* Uzun kelimelerin taşmasını önler */
  overflow-wrap: break-word; /* Modern tarayıcılar için ek destek */
}

.about-us h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
}

.about-us p {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
  text-align: justify; /* Metni hizalı hale getirir */
  max-width: 100%; /* Taşmayı önler */
}

.about-us button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-us button:hover {
  background-color: #0056b3;
}
