.phone-container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
    max-width: 600px;
  }
  
  .form-container {
    margin-bottom: 20px;
  }
  
  .input-field {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .save-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #2575fc;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 200px;
    margin: 10px 0;
  }
  
  .save-button:hover {
    background-color: #6a11cb;
  }
  
  .phone-list {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .phone-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    padding-top: 5px;
    flex-wrap: wrap;
  }
  
  .delete-button {
    padding: 5px 10px;
    font-size: 14px;
    background-color: red;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
  }
  