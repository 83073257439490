/* General Reset */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100%;
}

.admin-panel {
  display: flex;
  height: 100vh;
  background-color: #f0f0f0;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed; /* Fix sidebar to the left */
  top: 0;
  left: 0;
  height: 100%; /* Full viewport height */
  z-index: 10;
  overflow-y: auto; /* Allow scrolling if content exceeds viewport */
}

.sidebar button {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.sidebar button:hover {
  background-color: #555;
}

.sidebar button:focus {
  outline: none;
  background-color: #555;
}

/* Logout button */
.sidebar .MuiButton-root {
  margin-top: auto; /* Push logout button to the bottom */
}

/* Content Area */
.content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px; /* Account for sidebar width */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.5rem;
  color: #333;
  overflow-y: auto; /* Allow scrolling for content */
}

/* Responsiveness */
@media screen and (max-width: 768px) {
  .admin-panel {
    flex-direction: row;
  }

  .sidebar {
    width: 200px; /* Narrow sidebar for smaller screens */
  }

  .content {
    margin-left: 200px; /* Adjust content margin */
  }

  .sidebar button {
    font-size: 0.9rem;
    padding: 8px 10px;
  }
}

@media screen and (max-width: 480px) {
  .sidebar {
    width: 180px; /* Further narrow sidebar for extra small screens */
  }

  .content {
    margin-left: 180px;
  }

  .sidebar button {
    font-size: 0.8rem;
    padding: 6px 8px;
  }
}
