/* General Styles for Project Details */
.project-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px; /* Daha geniş bir alan belirledik */
  margin: 0 auto;
}

.project-photo {
  width: 90%; /* Fotoğraf genişliği artırıldı */
  max-width: 600px; /* Fotoğraf maksimum genişliği artırıldı */
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-description {
  margin-top: 16px;
  font-size: 1.2em; /* Yazı büyüklüğü artırıldı */
  color: #666;
  line-height: 1.8; /* Daha okunabilir hale getirildi */
  text-align: justify;
  width: 100%; /* Tüm genişliği kullanması sağlandı */
  max-width: 900px; /* Maksimum genişlik sınırı konuldu */
}

h2 {
  font-size: 2em; /* Başlık büyüklüğü artırıldı */
  margin-bottom: 20px;
  text-align: center; /* Başlığı ortala */
  width: 100%; /* Tüm genişliği kullanması sağlandı */
  max-width: 900px; /* Maksimum genişlik sınırı konuldu */
  word-wrap: break-word; /* Taşan kelimeleri kır */
}

/* Comment Form Styles */
.comment-form {
  margin-top: 30px; /* Form ve detaylar arasına boşluk */
  text-align: left;
  max-width: 500px;
  width: 100%; /* Tam genişlik */
  margin: 30px auto; /* Ortala */
}

.comment-form h3 {
  margin-bottom: 15px;
  font-size: 1.4em;
  font-weight: bold;
}

.comment-form input,
.comment-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.comment-form textarea {
  resize: vertical; /* Yatay boyutlandırmayı kapat */
  min-height: 100px; /* Minimum yükseklik */
}

.comment-form button {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.comment-form button:hover {
  background-color: #0056b3; /* Hover rengi */
}

.comment-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.comment-form .error {
  color: red;
  font-size: 0.9em;
  margin-top: -10px;
}
