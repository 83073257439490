.footer-section {
  background-color: #000; /* Siyah arka plan */
  color: #fff; /* Yazıları beyaz yap */
  padding: 20px 0;
}

/* Daha spesifik bir düzeltme gerekiyorsa */
.footer-section h3, 
.footer-section p, 
.footer-section a {
  color: #fff; /* Başlıklar, paragraflar ve bağlantılar beyaz */
}

/* T ile gelen yazıların beyaz olması */
.footer-section .address-list p,
.footer-section .phone-list p,
.footer-section .email-section a {
  color: #fff; /* Tüm adres, telefon ve e-posta yazıları beyaz */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  flex-wrap: wrap;  /* Allows wrapping on smaller screens */
}

.footer-left, .footer-center, .footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px 0;
}

/* Make the background of the center section transparent */
.footer-center {
  background-color: transparent; /* Transparent background */
  box-shadow: none; /* Remove any box-shadow */
  border: none; /* Ensure there is no border */
}

.footer-left h3, .footer-center h3, .footer-right h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-left p, .footer-center p, .footer-right .social-media-buttons {
  font-size: 14px;
}

.footer-center .address-list {
  background-color: transparent; /* Transparent background for the address list */
  max-height: 200px;  /* Maximum height for address list */
  overflow-y: auto;  /* Allows scrolling when content exceeds max height */
  box-shadow: none; /* Ensure no shadow for the address list */
}

.footer-center .address-item {
  background-color: transparent; /* Transparent background for each address item */
  padding: 10px;
  border: none; /* Ensure there is no border */
  box-shadow: none; /* Remove any shadow or outline */
}

.footer-center .address-item:last-child {
  border-bottom: 1px solid #fff; /* Son öğe için de çizgi ekler */
  margin-bottom: 0; /* Alt boşluğu kaldırır */
}

.footer-center .address-item.has-border {
  border-bottom: 1px solid #fff; /* Adds a border between addresses */
  margin-bottom: 10px;
}

.footer-center .address-item p {
  margin: 0;
}

.social-media-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.social-icon {
  text-decoration: none;
  color: #fff;
  padding: 8px 15px;
  background-color: #007bff;
  border-radius: 100px;
  font-size: 14px;
}

/* Footer bottom styling */
.footer-bottom {
  background-color: #333; /* Set footer bottom to dark gray */
  padding: 10px 0;
  text-align: center;
}

.footer-company {
  font-size: 16px;
  background-color: #333; /* Dark gray background for the footer company section */
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;
}

/* Logo Styling */
.footer-logo {
  width: 150px; /* Increased logo size */
  height: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-left, .footer-center, .footer-right {
    align-items: center;
  }

  .social-icon {
    padding: 5px 10px; /* Smaller padding for mobile */
    font-size: 12px;
  }

  .footer-left h3, .footer-center h3, .footer-right h3 {
    font-size: 16px; /* Slightly smaller headers */
  }

  .footer-company {
    font-size: 14px;
  }

  .footer-links a {
    font-size: 12px;
  }

  .footer-center .address-list {
    max-height: 150px; /* Adjust max height for mobile */
  }

  .footer-logo {
    width: 130px; /* Slightly smaller on medium screens */
  }
}

@media (max-width: 480px) {
  .footer-logo {
    width: 120px; /* Even smaller for very small screens */
  }
}
