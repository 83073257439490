@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.home-container {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background-color: white;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  max-width: 90%;
  word-wrap: break-word;
  z-index: 2;
  pointer-events: auto;
}

.slide {
  position: relative;
  width: 100%;
  height: 85vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-image 1s ease-in-out, opacity 0.5s ease;
}

.slide.hidden {
  opacity: 0;
}

.slide-title {
  font-size: 3rem;
  font-weight: 800;
  color: #D4AF37; /* Altın ton */
  margin-bottom: 60px;
  line-height: 1.2;
}

.corporate-button {
  color: #FFFFFF;
  font-weight: bold;
  padding: 12px 24px;
  font-size: 1.2rem;
  background-color: transparent;
  border: 2px solid #FFFFFF;
  position: relative;
  z-index: 3;
  transition: all 0.3s ease;
}

.corporate-button:hover {
  background-color: #D4AF37;
  color: #FFFFFF;
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.5);
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button:hover,
.next-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.indicator {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.indicator.active {
  background-color: #FFFFFF;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .slide {
    background-position: top;
    height: 75vh;
  }

  .overlay {
    font-size: 1.2rem;
    max-width: 80%;
  }

  .slide-title {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }

  .corporate-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .slide {
    background-position: top;
    height: 65vh;
  }

  .overlay {
    font-size: 1rem;
  }

  .slide-title {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }

  .corporate-button {
    width: 100%; /* Tam genişlik */
    text-align: center;
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
