/* Tamamlanan Projelerimizin başlık stilini ortalamak için yapılan düzenlemeler */
.completed-projects-carousel {
  padding: 20px; /* Sayfanın kenarlarına genel boşluk bırak */
  text-align: center; /* Başlık ve içeriklerin yatayda ortalanmasını sağlar */
  max-width: 1200px; /* Carousel genişliği sabit kalacak */
  margin: 0 auto; /* Carousel merkezi hizalanacak */
}

.completed-projects-carousel h2 {
  font-size: 2.5rem; /* Başlık boyutunu büyütüyoruz */
  font-weight: bold;
  color: #333; /* Başlık rengi */
  margin: 0 auto; /* Başlık ile üst kısmı arasındaki boşluğu sıfırlıyoruz */
  padding: 20px 0; /* Başlık ile diğer içerikler arasındaki boşluğu artırıyoruz */
  text-align: center; /* Başlığı tam ortalıyoruz */
  width: 100%; /* Başlık tüm genişliği kaplasın */
  display: block; /* Block display, böylece margin: auto işlevini görecek */
}



.slick-prev,
.slick-next {
  font-size: 0; /* Görünüm için semboller eklenecek */
  color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  color: #fff;
}

.slick-prev {
  left: -25px; /* Sol buton pozisyonu */
}

.slick-next {
  right: -25px; /* Sağ buton pozisyonu */
}

/* Kartlar */
.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none; /* Alt çizgiyi kaldırır */
  color: inherit; /* Varsayılan renk kullanılır */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px; /* Kartlar arasında boşluk */
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 150px; /* Kart yüksekliği sabit */
  min-width: 250px; /* Kart genişliği tutarlı */
}

/* Hover Efekti */
.project-card:hover {
  transform: translateY(-10px); /* Kart dışarı doğru hareket eder */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Daha güçlü gölge efekti */
}

/* Başlık ve Açıklama */
.project-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.project-card p {
  color: #666;
  font-size: 0.9em;
}

/* Slider Kart Boşluk Ayarları */
.slick-slide {
  padding: 0 10px; /* Kartlar arasında yatay boşluk */
}

/* Tıklanabilir Link Stilleri */
.project-card a {
  text-decoration: none; /* Alt çizgiyi kaldırır */
  color: inherit; /* Varsayılan renk */
  display: flex;
  flex-direction: column;
  height: 100%; /* Kartın tamamını kapsar */
}

/* Responsive Ayarlar */
@media (max-width: 1024px) {
  .completed-projects-carousel {
    padding: 20px; /* Tablet görünümde yan boşluk korundu */
  }
}
