/* Arka Plan ve Genel Sayfa Stil */
.contact-page {
    background: linear-gradient(135deg, #f0f4f8, #ffffff);
    font-family: 'Roboto', sans-serif;
    color: #333;
    padding: 40px 20px;
    min-height: 100vh;
}

.contact-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.contact-container:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Başlık Stilleri */
.contact-title {
    text-align: center;
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 30px;
    font-weight: bold;
}

.section-title {
    font-size: 1.8rem;
    color: #34495e;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-weight: 600;
}

/* Divider */
.divider {
    border: none;
    height: 1px;
    background: #dfe6e9;
    margin: 30px 0;
}

/* Listeler */
.address-list, .phone-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.address-item, .phone-item {
    flex: 1 1 calc(50% - 20px); /* İki sütun yap */
    background: #ecf0f1;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.address-item:hover, .phone-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

strong {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #2c3e50;
}

/* Link Stilleri */
.phone-link, .email-link {
    color: #3498db;
    text-decoration: none;
    font-size: 1.1rem;
}

.phone-link:hover, .email-link:hover {
    text-decoration: underline;
}

/* E-posta Bölümü */
.email-section {
    text-align: center;
}

.email-item {
    font-size: 1.2rem;
    margin-top: 15px;
}

/* Yükleniyor ve Hata Mesajları */
.loading {
    text-align: center;
    font-size: 1.2rem;
    color: #3498db;
}

.error {
    text-align: center;
    font-size: 1.2rem;
    color: #e74c3c;
    margin-top: 20px;
}

/* Responsive Tasarım */
@media (max-width: 768px) {
    .address-item, .phone-item {
        flex: 1 1 100%;
    }

    .contact-title {
        font-size: 2rem;
    }

    .section-title {
        font-size: 1.5rem;
    }
}